import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetMealPlanQuery, useClearMealPlanHistoryMutation } from '../../store/apiSlice';
import { setMealIdeas, setMealIdeasStreaming } from './mealPlanSlice';
import { BASE_WS_URL } from '../../constants';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';

const MealPlanChat = () => {
    const dispatch = useDispatch();
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const mealIdeas = useSelector(state => state.mealPlan.mealIdeas);
    const mealIdeasStreaming = useSelector(state => state.mealPlan.mealIdeasStreaming);
    const { data: mealPlan, isLoading, error, refetch } = useGetMealPlanQuery(meal_plan_id);
    const [feedbackValue, setFeedbackValue] = useState('');
    const ws = useRef(null);
    const chatHistory = mealPlan?.chat_history?.map(entry => entry.user_feedback) || [];
    const initialMealIdeas = mealPlan?.meal_ideas || {};
    const [showHistory, setShowHistory] = useState(false);
    const [clearMealPlanHistory] = useClearMealPlanHistoryMutation();

    useEffect(() => {
        if (mealPlan && mealPlan.chat_history) {
            dispatch(setMealIdeas(initialMealIdeas));
        }
    }, [mealPlan, dispatch, initialMealIdeas]);

    const initiateWebSocketConnection = useCallback((userFeedback) => {
        if (ws.current) {
            ws.current.close();
            ws.current = null;
        }

        ws.current = new WebSocket(`${BASE_WS_URL}/generate_meal_ideas`);
        ws.current.onopen = () => {
            console.log('WebSocket is connected');
            dispatch(setMealIdeasStreaming(true));
            const payload = {
                meal_plan_id: meal_plan_id,
                user_feedback: userFeedback,
            };
            ws.current.send(JSON.stringify({ payload }));
        };

        ws.current.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data && typeof data === 'object') {
                    if (data.end_of_stream) {
                        console.log('End of stream received, closing WebSocket.');
                        ws.current.close();
                        ws.current = null;
                        dispatch(setMealIdeasStreaming(false));
                        refetch().then((result) => {
                            if (result.data && result.data.chat_history) {
                                dispatch(setMealIdeas(result.data.meal_ideas));
                            }
                        });
                    } else {
                        dispatch(setMealIdeas(data));
                    }
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error);
            dispatch(setMealIdeasStreaming(false));
        };

        ws.current.onclose = () => {
            console.log('WebSocket is closed');
            ws.current = null;
            dispatch(setMealIdeasStreaming(false));
        };
    }, [meal_plan_id, dispatch, refetch]);

    const handleFeedbackSubmit = useCallback(() => {
        initiateWebSocketConnection(feedbackValue.trim());
        setFeedbackValue('');
    }, [feedbackValue, initiateWebSocketConnection]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleFeedbackSubmit();
        }
    };

    const toggleHistoryDisplay = () => {
        setShowHistory(!showHistory);
    };

    const handleClearHistory = useCallback(async () => {
        try {
            await clearMealPlanHistory(meal_plan_id);
            refetch();
        } catch (error) {
            console.error('Error clearing meal plan history:', error);
        }
    }, [clearMealPlanHistory, meal_plan_id, refetch]);

    if (isLoading) {
        return <div>Loading meal plan...</div>;
    }

    if (error) {
        return <div>Error loading meal plan: {error.message}</div>;
    }

    return (
        <div className="p-2">
            {mealIdeasStreaming && (
                <span className="loading loading-dots loading-lg"></span>
            )}
            {chatHistory.length > 0 && (
                <div className="flex space-x-2 mb-2">
                    <Button
                        variant="link"
                        onClick={toggleHistoryDisplay}
                        size='sm'
                    >
                        {showHistory ? "Hide history" : "Show history"}
                    </Button>
                    <Button
                        variant="link"
                        onClick={handleClearHistory}
                        size='sm'
                    >
                        Clear History
                    </Button>
                </div>
            )}
            <div className="flex space-x-2">
                <Input
                    className="flex-grow"
                    placeholder="What do you want to cook?"
                    value={feedbackValue}
                    onChange={(e) => setFeedbackValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <Button
                    onClick={handleFeedbackSubmit}
                    disabled={mealIdeasStreaming}
                    variant="reverse"
                >
                    {feedbackValue.trim() ? "Get ideas" : "Surprise me!"}
                </Button>
            </div>
        </div>
    );
};

export default MealPlanChat;
