export const SUPABASE_URL = 'https://hqgydqhtxotfklkhrjkc.supabase.co'
export const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhxZ3lkcWh0eG90Zmtsa2hyamtjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjczMTI3NzcsImV4cCI6MjA0Mjg4ODc3N30.Af-_f51eDoCYpzTqic0aZnxyXkdSwrr00TAwc5SvBwY'

// prod
export const DOMAIN = 'api.miseen.xyz';
export const BASE_API_URL = `https://${DOMAIN}`;
export const BASE_WS_URL = `wss://${DOMAIN}`;
export const CALLBACK_URL = 'https://miseen.xyz/auth/callback';

// dev
// export const DOMAIN = '0.0.0.0:8000';
// export const BASE_API_URL = `http://${DOMAIN}`;
// export const BASE_WS_URL = `ws://${DOMAIN}`;
// export const CALLBACK_URL = 'http://localhost:3000/auth/callback';