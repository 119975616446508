import React from 'react';

const ProfileArea = ({ title, children }) => {
  return (
    <div className="border border-black rounded-lg bg-white p-4 relative mb-8">
      <h3 className="font-semibold">{title}</h3>
      <div className="mt-2">
        {children}
      </div>
    </div>
  );
};

export default ProfileArea;
