import * as React from "react"
import { ChevronDown } from "lucide-react"
import { Button } from "./Button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./DropdownMenu"
import { cn } from "src/lib/utils"

type Action = {
  label: string;
  onClick: () => void;
}

interface MultiActionButtonProps {
  actions: Action[];
  className?: string;
}

export const MultiActionButton: React.FC<MultiActionButtonProps> = ({
  actions,
  className,
}) => {
  if (actions.length === 0) {
    return null;
  }

  const primaryAction = actions[0];
  const secondaryActions = actions.slice(1);
  const hasSecondaryActions = secondaryActions.length > 0;

  return (
    <div className={cn("flex items-center", className)}>
      <Button 
        className={cn(hasSecondaryActions && "rounded-r-none")}
        variant="noShadow"
        size="sm"
        onClick={primaryAction.onClick}
        aria-haspopup={hasSecondaryActions ? "menu" : undefined}
      >
        {primaryAction.label}
      </Button>
      {hasSecondaryActions && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              variant="noShadow" 
              size="sm"
              className="rounded-l-none border-l-0 px-2"
              aria-label="More actions"
            >
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {secondaryActions.map((action, index) => (
              <DropdownMenuItem key={index} onSelect={action.onClick}>
                {action.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}
