import React from 'react';
import Card from '../../components/Card';
const CreateYourOwnRecipeButton = ({ onClick, className, searchTerm }) => {
    return (
        <Card className="w-full max-w-[320px] cursor-pointer bg-green hover:bg-green p-2" boxShadowColor='yellow' onClick={onClick}>
            <div className="flex flex-col">
                <div className="text-lg line-clamp-2 text-yellow">
                    {searchTerm}
                </div>
                <div className="text-xs mt-2 text-yellow">
                    Build your own simple recipe
                </div>
            </div>
        </Card>
    );
};

export default CreateYourOwnRecipeButton;
