import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from 'src/components/Dialog';
import { Button } from 'src/components/Button';

const PollForIngredientModal = ({ isOpen, onClose, onRetry, maxRetries = 12 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        let intervalId;
        if (isOpen && retryCount < maxRetries) {
            intervalId = setInterval(() => {
                onRetry()
                    .then(() => {
                        clearInterval(intervalId);
                        onClose();
                    })
                    .catch(() => {
                        if (retryCount >= maxRetries - 1) {
                            setError(true);
                            clearInterval(intervalId);
                        } else {
                            setRetryCount(count => count + 1);
                        }
                    });
            }, 5000);
        }

        return () => clearInterval(intervalId);
    }, [isOpen, retryCount, maxRetries, onRetry, onClose]);

    const handleClose = () => {
        onClose();
        setError(false);
        setRetryCount(0);
    };

    return (
        <Dialog open={isOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black">
                <DialogHeader>
                    <DialogTitle>Processing Ingredients</DialogTitle>
                </DialogHeader>
                <div className="p-4">
                    {error ? (
                        <p>We are unable to process your ingredients at this time. Please try again later.</p>
                    ) : (
                        <div>
                            <p>It may take up to a minute to parse all of your ingredients for the grocery list. Please wait...</p>
                            <span className="loading loading-dots loading-lg"></span>
                        </div>
                    )}
                    <DialogFooter>
                        <Button onClick={handleClose} variant="destructive">Cancel creating grocery list</Button>
                    </DialogFooter>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PollForIngredientModal;