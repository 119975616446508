import React from 'react';

const NutritionBar = ({ protein_calories, fat_calories, carb_calories, size = "md" }) => {
  const totalCalories = protein_calories + fat_calories + carb_calories;
  
  let proteinPercentage = Math.round((protein_calories / totalCalories) * 100);
  let fatPercentage = Math.round((fat_calories / totalCalories) * 100);
  let carbPercentage = Math.round((carb_calories / totalCalories) * 100);

  // Ensure percentages sum to 100
  const sum = proteinPercentage + fatPercentage + carbPercentage;
  if (sum !== 100) {
    const diff = 100 - sum;
    if (proteinPercentage >= fatPercentage && proteinPercentage >= carbPercentage) {
      proteinPercentage += diff;
    } else if (fatPercentage >= proteinPercentage && fatPercentage >= carbPercentage) {
      fatPercentage += diff;
    } else {
      carbPercentage += diff;
    }
  }
  const fontSize = size === "lg" ? "text-sm" : "text-[10px]";
  
  // Calculate grams
  const proteinGrams = Math.round(protein_calories / 4);
  const fatGrams = Math.round(fat_calories / 9);
  const carbGrams = Math.round(carb_calories / 4);

  return (
    <div className={`w-[${size === "lg" ? "512" : "172"}px]`}>
      <div>
        <div className={`${fontSize} text-black font-['Space_Mono'] mr-2`}>Calories:{Math.round(totalCalories)}</div>
      </div>
      <div className="flex justify-between">
        <div className={`${fontSize} text-black font-['Space_Mono'] mr-2`}>
          Protein:{size === "lg" ? `${proteinPercentage}% (${proteinGrams}g)` : `${proteinGrams}g`}
        </div>
        <div className={`${fontSize} text-black font-['Space_Mono'] mr-2`}>
          Fat:{size === "lg" ? `${fatPercentage}% (${fatGrams}g)` : `${fatGrams}g`}
        </div>
        <div className={`${fontSize} text-black font-['Space_Mono']`}>
          Carbs:{size === "lg" ? `${carbPercentage}% (${carbGrams}g)` : `${carbGrams}g`}
        </div>
      </div>
      <div className={`${size === "md" ? "h-[6px]" : "h-4"} bg-gray-200 overflow-hidden flex`}>
        <div 
          className="bg-blue border border-black" 
          style={{ width: `${proteinPercentage}%` }}
          title={`Protein: ${protein_calories} calories`}
        />
        <div 
          className="bg-pink border border-black" 
          style={{ width: `${fatPercentage}%` }}
          title={`Fat: ${fat_calories} calories`}
        />
        <div 
          className="bg-purple border border-black" 
          style={{ width: `${carbPercentage}%` }}
          title={`Carbs: ${carb_calories} calories`}
        />
      </div>
    </div>
  );
};

export default NutritionBar;
