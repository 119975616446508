import React from 'react';
import CreateRecipe from './CreateRecipe';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from 'src/components/Dialog';

interface CreateRecipeModalProps {
  initialMessage: string;
  isOpen: boolean;
  onClose: () => void;
  saveCallback: (recipeId: string) => void;
}

const CreateRecipeModal: React.FC<CreateRecipeModalProps> = ({ initialMessage, isOpen, onClose, saveCallback }) => {
  const handleSave = async (recipeId: string) => {
    saveCallback(recipeId);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent variant="wide">
        <DialogHeader>
          <DialogTitle>Create Recipe</DialogTitle>
        </DialogHeader>
        <div className="my-4 flex flex-col space-y-4 items-start">
          <CreateRecipe
            saveCallback={handleSave}
            initialMessage={initialMessage}
          />
        </div>
        <DialogFooter>
          <button 
            className="btn p-2 rounded bg-red-500 text-black border-none" 
            onClick={onClose}
          >
            Cancel
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateRecipeModal;