import React from 'react';
import RecipeList from './RecipeList';

function RecipeLibrary() {
    return (
        <div className="max-w-[1024px] mx-auto px-8">
            <div className="flex flex-wrap justify-between items-end pt-16 pb-4 relative">
                <h1 className="text-4xl font-bold">
                    Library
                </h1>
            </div>
            
            <RecipeList />
        </div>
    );
}

export default RecipeLibrary;