import React from 'react';
import RecipeList from '../recipeLibrary/RecipeList';
import { useSelector, useDispatch } from 'react-redux';
import { openAddToMealModal } from './mealPlanSlice';

function RecipeLibraryTab() {
    const dispatch = useDispatch();
    const mealPlanId = useSelector(state => state.mealPlan.meal_plan_id);

    const handleAddToMeal = (recipe) => {
        dispatch(openAddToMealModal({
            recipeId: recipe.recipe_id,
            recipeName: recipe.name
        }));
    };

    const additionalActions = [
        {
            text: 'Add to Meal',
            handler: handleAddToMeal
        }
    ];

    return (
        <div className="pr-4">
            <RecipeList additionalActions={additionalActions} />
        </div>
    );
}

export default RecipeLibraryTab;