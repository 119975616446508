import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'src/components/Dialog';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { v4 as uuidv4 } from 'uuid';
import { Label } from 'src/components/Label';
import { Badge } from 'src/components/Badge';
import { setEditingMember } from './profileSlice';

const HouseholdMemberDialog = ({ isOpen, onClose, onSave, isNewMember }) => {
  const dispatch = useDispatch();
  const editingMember = useSelector((state) => state.profile.editingMember);

  useEffect(() => {
    if (isNewMember && isOpen) {
      dispatch(setEditingMember({
        id: uuidv4(),
        customer_facing_name: '',
        carb_percentage: 0,
        fat_percentage: 0,
        protein_percentage: 0,
        calorie_target: 0,
        calorie_allocation: {
          breakfast: 0.25,
          lunch: 0.3,
          dinner: 0.35,
          snack: 0.1
        }
      }));
    }
  }, [isOpen, isNewMember, dispatch]);

  const percentageSum = useMemo(() => {
    if (!editingMember) return 0;
    return editingMember.carb_percentage + editingMember.fat_percentage + editingMember.protein_percentage;
  }, [editingMember]);

  const isPercentageValid = Math.abs(percentageSum - 1) < 0.001;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setEditingMember({
      ...editingMember,
      [name]: name === 'customer_facing_name' ? value : parseFloat(value),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingMember) {
      console.log(editingMember);
      onSave(editingMember);
    }
    onClose();
  };

  if (!editingMember) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent variant="wide">
        <DialogHeader>
          <DialogTitle>{editingMember.id ? 'Edit' : 'Add'} Household Member</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <DialogDescription>
            <div className="space-y-8">
              {/* Row 1: Name */}
              <div>
                <Label htmlFor="customer_facing_name">Name</Label>
                <Input
                  id="customer_facing_name"
                  name="customer_facing_name"
                  value={editingMember.customer_facing_name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                  className="max-w-md"
                />
              </div>

              {/* Row 2: Nutrition Targets */}
              <div>
                <h3 className="text-base font-semibold mb-4">Nutrition Targets</h3>
                <div className="grid grid-cols-4 gap-4">
                  <div>
                    <Label htmlFor="calorie_target">Daily Calories</Label>
                    <Input
                      id="calorie_target"
                      name="calorie_target"
                      type="number"
                      value={editingMember.calorie_target}
                      onChange={handleChange}
                      placeholder="Calories"
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="carb_percentage">Carbs (%)</Label>
                    <Input
                      id="carb_percentage"
                      name="carb_percentage"
                      type="number"
                      step="0.05"
                      min="0"
                      max="1"
                      value={editingMember.carb_percentage}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="fat_percentage">Fats (%)</Label>
                    <Input
                      id="fat_percentage"
                      name="fat_percentage"
                      type="number"
                      step="0.05"
                      min="0"
                      max="1"
                      value={editingMember.fat_percentage}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="protein_percentage">Protein (%)</Label>
                    <Input
                      id="protein_percentage"
                      name="protein_percentage"
                      type="number"
                      step="0.05"
                      min="0"
                      max="1"
                      value={editingMember.protein_percentage}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                {!isPercentageValid && (
                  <div className="mt-2 flex items-center justify-end">
                    <Badge variant="destructive">
                      Percentages must add up to 100%
                    </Badge>
                  </div>
                )}
              </div>

              {/* Row 3: Meal Schedule */}
              <div>
                <h3 className="text-base font-semibold mb-4">Daily Meal Schedule</h3>
                <div className="grid grid-cols-4 gap-4">
                  <div>
                    <Label htmlFor="breakfast">Breakfast (%)</Label>
                    <Input
                      id="breakfast"
                      name="calorie_allocation.breakfast"
                      type="number"
                      step="0.05"
                      min="0"
                      max="1"
                      value={editingMember.calorie_allocation.breakfast}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        dispatch(setEditingMember({
                          ...editingMember,
                          calorie_allocation: {
                            ...editingMember.calorie_allocation,
                            breakfast: value
                          }
                        }));
                      }}
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="lunch">Lunch (%)</Label>
                    <Input
                      id="lunch"
                      name="calorie_allocation.lunch"
                      type="number"
                      step="0.05"
                      min="0"
                      max="1"
                      value={editingMember.calorie_allocation.lunch}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        dispatch(setEditingMember({
                          ...editingMember,
                          calorie_allocation: {
                            ...editingMember.calorie_allocation,
                            lunch: value
                          }
                        }));
                      }}
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="dinner">Dinner (%)</Label>
                    <Input
                      id="dinner"
                      name="calorie_allocation.dinner"
                      type="number"
                      step="0.05"
                      min="0"
                      max="1"
                      value={editingMember.calorie_allocation.dinner}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        dispatch(setEditingMember({
                          ...editingMember,
                          calorie_allocation: {
                            ...editingMember.calorie_allocation,
                            dinner: value
                          }
                        }));
                      }}
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="snack">Snacks (%)</Label>
                    <Input
                      id="snack"
                      name="calorie_allocation.snack"
                      type="number"
                      step="0.05"
                      min="0"
                      max="1"
                      value={editingMember.calorie_allocation.snack}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        dispatch(setEditingMember({
                          ...editingMember,
                          calorie_allocation: {
                            ...editingMember.calorie_allocation,
                            snack: value
                          }
                        }));
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button type="submit" disabled={!isPercentageValid}>Save</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default HouseholdMemberDialog; 