import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from './Sheet';
import { closeRecipeSheet } from '../store/globalSlice';
import RecipeFull from './RecipeFull';

export default function RecipeSheet() {
    const dispatch = useDispatch();
    const { isOpen, recipeData } = useSelector((state) => state.global.recipeSheet);

    if (!recipeData) return null;

    return (
        <Sheet open={isOpen} onOpenChange={() => dispatch(closeRecipeSheet())}>
            <SheetContent side="right" className="w-full max-w-2xl">
                <SheetHeader>
                    <SheetTitle>{recipeData.recipe_name}</SheetTitle>
                </SheetHeader>
                
                <div className="overflow-y-auto max-h-[calc(100vh)]">
                    <RecipeFull recipe={recipeData} renderTitle={false} />
                </div>
            </SheetContent>
        </Sheet>
    );
} 