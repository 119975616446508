import React, { useState } from 'react';
import { useListMealsQuery, useAddRecipeToMealMutation, useCreateGroceryListMutation, usePublishMealsMutation, useGetMealPlanQuery, useGetUserQuery } from '../../store/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MealPlanMeal from './MealPlanMeal';
import AdjustQuantityModal from './AdjustQuantityModal';
import PollForIngredientModal from './PollForIngredientModal';
import { closeAdjustQuantityModal } from './mealPlanSlice';
import { Button } from '../../components/Button';
import { Switch } from '../../components/Switch';
import { Label } from '../../components/Label';

const MealPlanMealList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const { data: mealPlan } = useGetMealPlanQuery(meal_plan_id);
    const { data, error: mealsError, isLoading: mealsLoading } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const mealsData = data?.meals;
    const adjustQuantityModal = useSelector(state => state.mealPlan.adjustQuantityModal);
    const [addRecipeToMeal] = useAddRecipeToMealMutation();
    const [createGroceryList, { isLoading: isCreatingGroceryList }] = useCreateGroceryListMutation();
    const [publishMeals] = usePublishMealsMutation();
    const [pollModalOpen, setPollModalOpen] = useState(false);
    const [isSimpleView, setIsSimpleView] = useState(false);

    const hasGroceryList = mealPlan && Boolean(mealPlan.grocery_list_id);
    console.log("Meals", mealsData)

    const handleCloseAdjustQuantityModal = () => {
        dispatch(closeAdjustQuantityModal());
    };

    const handleAdjustQuantitySubmit = async (multiplier) => {
        try {
            await addRecipeToMeal({
                meal_id: adjustQuantityModal.mealId,
                recipe_id: adjustQuantityModal.recipeId,
                multiplier,
            });
            handleCloseAdjustQuantityModal();
        } catch (error) {
            console.error('Failed to adjust recipe quantity:', error);
        }
    };

    const handleCreateGroceryList = async () => {
        if (!mealsData || mealsData.length === 0) return;

        const mealIds = mealsData.map(meal => meal.id);
        
        try {
            // Publish meals first
            await publishMeals(mealIds);

            // Then create the grocery list
            const result = await createGroceryList({ meal_ids: mealIds, meal_plan_id });

            if (result.data && result.data.grocery_list_id) {
                navigate(`/grocery_list/${result.data.grocery_list_id}`);
            }
        } catch (error) {
            console.error('Error creating grocery list:', error);
            if (error.status === 400 && error.data?.detail?.includes("Couldn't find parsed ingredients")) {
                setPollModalOpen(true);
            }
        }
    };

    const handleNavigateToGroceryList = () => {
        navigate(`/grocery_list/${mealPlan.grocery_list_id}`);
    };

    const retryCreateGroceryList = async () => {
        try {
            const mealIds = mealsData.map(meal => meal.id);
            const result = await createGroceryList({ meal_ids: mealIds, meal_plan_id });

            if (result.data && result.data.grocery_list_id) {
                setPollModalOpen(false);
                navigate(`/grocery_list/${result.data.grocery_list_id}`);
            }
        } catch (error) {
            console.error('Retry error in creating grocery list:', error);
            if (error.status === 400 && error.data?.detail?.includes("Couldn't find parsed ingredients")) {
                throw new Error("Failed to create grocery list after retries.");
            }
        }
    };

    const filteredMeals = mealsData ? mealsData.filter(meal => meal.primary_meal === null) : [];
    
    // New function to get leftovers for a meal
    const getLeftoversForMeal = (mealId) => {
        console.log('mealId:', mealId);
        return mealsData ? mealsData.filter(meal => meal.primary_meal?.meal_id === mealId) : [];
    };

    const someMealsHaveRecipes = filteredMeals.some(meal => meal.recipes && meal.recipes.length > 0);
    const allMealsHaveRecipes = filteredMeals.every(meal => meal.recipes && meal.recipes.length > 0);


    return (
        <div className="flex flex-col h-full pt-4 pb-32 w-sm">
            <div className="flex items-center justify-between pb-4">
                <div className="text-2xl font-bold">Meal Plan</div>
                <div className="flex items-center gap-2">
                    <Label htmlFor="simple-view" className="text-sm">
                        Simple View
                    </Label>
                    <Switch
                        id="simple-view"
                        checked={isSimpleView}
                        onCheckedChange={setIsSimpleView}
                    />
                </div>
            </div>
            <div>
                {mealsLoading ? (
                    <div className="p-4">Loading meals...</div>
                ) : mealsError ? (
                    <div className="p-4">Error loading meals: {mealsError.message}</div>
                ) : mealsData && mealsData.length > 0 ? (
                    filteredMeals.map((meal) => (
                        <MealPlanMeal 
                            key={`${meal.date}-${meal.meal_type}`} 
                            meal={meal}
                            leftovers={getLeftoversForMeal(meal.id)}
                            isSimpleView={isSimpleView}
                        />
                    ))
                ) : (
                    <p className="p-4">No meals found in this meal plan.</p>
                )}
                {someMealsHaveRecipes && (
                    hasGroceryList ? (
                        <Button 
                            variant={allMealsHaveRecipes ? "default" : "link"}
                            size={allMealsHaveRecipes ? "default" : "defaultNoPadding"}
                            onClick={handleNavigateToGroceryList}
                            className="text-sm"
                        >
                            Go to Grocery List
                        </Button>
                    ) : (
                        <Button 
                            variant={allMealsHaveRecipes ? "default" : "link"}
                            size={allMealsHaveRecipes ? "default" : "defaultNoPadding"}
                            onClick={handleCreateGroceryList} 
                            disabled={isCreatingGroceryList}
                            className="text-sm"
                        >
                            Create Grocery List
                        </Button>
                    )
                )}
            </div>
            <AdjustQuantityModal
                isOpen={adjustQuantityModal.isOpen}
                onClose={handleCloseAdjustQuantityModal}
                onSubmit={handleAdjustQuantitySubmit}
                initialMultiplier={adjustQuantityModal.initialMultiplier}
            />
            <PollForIngredientModal
                isOpen={pollModalOpen}
                onClose={() => setPollModalOpen(false)}
                onRetry={retryCreateGroceryList}
            />
        </div>
    );
};

export default MealPlanMealList;
