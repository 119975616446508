import React from 'react';
import { Button } from '../../components/Button';
import PreferenceStickyNote from './PreferenceStickyNote';
import EditPreferenceDialog from './EditPreferenceDialog';
import ProfileArea from './ProfileArea';

const ManagePreferences = ({
  user,
  editingPreference,
  addingPreference,
  onEditPreference,
  onUpdatePreference,
  onDeletePreference,
  onAddPreference,
  onSaveNewPreference,
  onClosePreferenceDialog
}) => {
  return (
    <ProfileArea title="Your Preferences">
      
      <h3 className="text-xl font-semibold mb-2">Recipe Generation</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-4">
        {user.preferences?.recipe_generation?.map(({ id, preference }) => (
          <PreferenceStickyNote
            key={id}
            preferenceKey={id}
            value={preference}
            onEdit={(_, value) => onEditPreference('recipe_generation', id, value)}
            onDelete={() => onDeletePreference('recipe_generation', id)}
          />
        ))}
        <Button 
          onClick={() => onAddPreference('recipe_generation')} 
          className="w-32 bg-yellow"
        >
          Add Sticky
        </Button>
      </div>

      <h3 className="text-xl font-semibold mb-2">Meal Planning Ideas</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {user.preferences?.meal_planning_ideas?.map(({ id, preference }) => (
          <PreferenceStickyNote
            key={id}
            preferenceKey={id}
            value={preference}
            onEdit={(_, value) => onEditPreference('meal_planning_ideas', id, value)}
            onDelete={() => onDeletePreference('meal_planning_ideas', id)}
          />
        ))}
        <Button 
          onClick={() => onAddPreference('meal_planning_ideas')} 
          className="w-32 bg-yellow"
        >
          Add Sticky
        </Button>
      </div>

      <EditPreferenceDialog
        isOpen={!!editingPreference || !!addingPreference}
        onClose={onClosePreferenceDialog}
        preferenceKey={editingPreference?.id || addingPreference?.id}
        value={editingPreference?.preference ?? addingPreference?.preference ?? ''}
        onSave={(_, newValue) => {
          if (editingPreference) {
            onUpdatePreference(editingPreference.category, editingPreference.id, newValue);
          } else if (addingPreference) {
            onSaveNewPreference(addingPreference.category, newValue);
          }
          onClosePreferenceDialog();
        }}
        isAdding={!!addingPreference}
      />
    </ProfileArea>  
  );
};

export default ManagePreferences;
