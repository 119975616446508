import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BuildSchedule from './BuildSchedule';
import { useGetHouseholdQuery, useCreateMealPlanMutation, useCreateMealMutation } from '../../store/apiSlice';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Button } from 'src/components/Button';

function BuildMealPlanStructure() {
  const [schedule, setSchedule] = useState({});
  const navigate = useNavigate();

  const { data: household, isLoading, isError } = useGetHouseholdQuery();
  const [createMealPlan] = useCreateMealPlanMutation();
  const [createMeal] = useCreateMealMutation();

  useEffect(() => {
    if (household) {
      const initialSchedule = createInitialSchedule(household);
      setSchedule(initialSchedule);
    }
  }, [household]);

  const createInitialSchedule = (household) => {
    const mealTypes = ['breakfast', 'lunch', 'snack', 'dinner'];
    let schedule = {};
  
    for (let i = 0; i < 14; i++) {
      const date = moment().add(i, 'days');
      const formattedDate = date.format('YYYY-MM-DD');
  
      mealTypes.forEach(mealType => {
        const mealId = uuidv4();
        schedule[mealId] = {
          id: mealId,
          date: date.toDate(),
          formattedDate: formattedDate,
          meal_type: mealType,
          users: household.reduce((acc, member) => {
            acc[member.user_id] = false;
            return acc;
          }, {}),
          leftoverFrom: 'fresh'
        };
      });
    }
    return schedule;
  };

  const updateSchedule = (mealId, field, value) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [mealId]: {
        ...prevSchedule[mealId],
        [field]: value
      }
    }));
  };

  const submitWizard = async () => {
    try {
      const response = await createMealPlan({ meal_inspiration: 'No inspiration provided' });
      const mealPlanId = response.data.meal_plan_id;

      const mealsToCreate = Object.values(schedule).filter(meal => 
        Object.values(meal.users).some(user => user)
      );

      // Transform users object to array of user IDs
      const getMealRecipients = (users) => {
        return Object.entries(users)
          .filter(([_, isSelected]) => isSelected)
          .map(([userId, _]) => userId);
      };

      // Separate fresh meals and leftover meals
      const freshMeals = mealsToCreate.filter(meal => meal.leftoverFrom === 'fresh');
      const leftoverMeals = mealsToCreate.filter(meal => meal.leftoverFrom !== 'fresh');

      // Create fresh meals first
      const createdFreshMeals = await Promise.all(freshMeals.map(async meal => {
        const { formattedDate, meal_type, users } = meal;
        const createMealRequest = { 
          date: formattedDate,
          meal_type, 
          meal_plan_id: mealPlanId, 
          published: false,
          meal_recipients: getMealRecipients(users)
        };

        const createdMeal = await createMeal(createMealRequest).unwrap();
        return { originalId: meal.id, createdId: createdMeal.meal_id };
      }));

      // Update the schedule with new IDs for fresh meals
      const updatedSchedule = { ...schedule };
      createdFreshMeals.forEach(({ originalId, createdId }) => {
        if (updatedSchedule[originalId]) {
          updatedSchedule[originalId].id = createdId;
        }
      });

      // Create leftover meals
      const createdLeftoverMeals = await Promise.all(leftoverMeals.map(async meal => {
        const { formattedDate, meal_type, leftoverFrom, users } = meal;
        const primaryMeal = updatedSchedule[leftoverFrom];
        const createMealRequest = { 
          date: formattedDate,
          meal_type, 
          meal_plan_id: mealPlanId, 
          published: false,
          meal_recipients: getMealRecipients(users),
          primary_meal: {
            meal_id: primaryMeal.id,
            date: primaryMeal.formattedDate,
            meal_type: primaryMeal.meal_type
          }
        };

        const createdMeal = await createMeal(createMealRequest).unwrap();
        return { originalId: meal.id, createdId: createdMeal.meal_id };
      }));

      // Update the schedule with new IDs for leftover meals
      createdLeftoverMeals.forEach(({ originalId, createdId }) => {
        if (updatedSchedule[originalId]) {
          updatedSchedule[originalId].id = createdId;
        }
      });

      setSchedule(updatedSchedule);

      navigate(`/meal_plan/${mealPlanId}`);
    } catch (error) {
      console.error('Error creating meal plan:', error);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading household data</div>;

  return (
    <div className="max-w-[600px] justify-center mx-auto px-4">
      <div className="flex flex-wrap justify-between items-end pt-16 pb-4 relative">
        <BuildSchedule schedule={schedule} household={household} updateSchedule={updateSchedule} />
      </div>
      <div className="fixed bottom-0 left-0 right-0 p-4 flex justify-center">
        <Button onClick={submitWizard}>Create Meal Plan</Button>
      </div>
    </div>
  );
}

export default BuildMealPlanStructure;
