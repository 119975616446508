import React from 'react';
import { ExternalLink } from 'lucide-react';
import NutritionBar from './NutritionBar';
import Card from './Card';
import { formatDuration, floatToString, formatIngredient } from '../utils';
import localIcon from '../assets/cooking_icon.png';

export default function RecipeFull({ recipe, renderTitle = true }) {
    const {
        recipe_name,
        image,
        rating_value,
        rating_count,
        recipe_yield,
        total_time,
        nutrition,
        url,
        favicon,
        domain_description,
        description,
        parsed_ingredients,
        parsed_instructions
    } = recipe;

    const RecipeMetadata = () => (
        <div className="grid grid-cols-[auto_1fr] gap-x-4 text-sm mb-2">
            {rating_value && rating_count && (
                <>
                    <span className="font-bold">Rating</span>
                    <span>{Number(rating_value).toFixed(1)} ({rating_count})</span>
                </>
            )}
            {recipe_yield && (
                <>
                    <span className="font-bold">Serves</span>
                    <span>{recipe_yield}</span>
                </>
            )}
            {total_time && (
                <>
                    <span className="font-bold">Total Time</span>
                    <span>{formatDuration(total_time)}</span>
                </>
            )}
        </div>
    );

    const ExternalLinkCard = () => (
        <a 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="block hover:bg-gray-50 transition-colors"
        >
            <Card className="flex items-center justify-between p-2 mt-2">
                <div className="flex items-center gap-2">
                    <img 
                        src={favicon || localIcon} 
                        onError={(e) => { e.target.onerror = null; e.target.src = localIcon; }}
                        alt={domain_description}
                        className="w-4 h-4"
                    />
                    <span className="text-sm line-clamp-1">{domain_description}</span>
                </div>
                <ExternalLink className="w-4 h-4 text-blue-600" />
            </Card>
        </a>
    );

    const Section = ({ title, children }) => (
        <div className="mt-6">
            <div className="text-xl font-semibold mb-2 pb-2 border-b-4 border-green">{title}</div>
            {children}
        </div>
    );

    // Convert ingredients to array if it's an object
    const ingredientsList = parsed_ingredients ? 
        (Array.isArray(parsed_ingredients) 
            ? parsed_ingredients 
            : Object.values(parsed_ingredients).map(ing => ing.parsed_ingredient)
        ) : [];

    return (
        <div className="max-w-3xl mx-auto mb-16">
            {renderTitle && (
                <h1 className="text-2xl font-bold mb-4">{recipe_name}</h1>
            )}

            <div className="flex gap-6 items-stretch">
                {image && (
                    <div className="w-[128px] shrink-0">
                        <img 
                            src={image} 
                            alt={recipe_name}
                            className="w-full h-full object-cover border border-black"
                        />
                    </div>
                )}
                <div className="flex-1">
                    <RecipeMetadata />
                    <NutritionBar 
                        protein_calories={nutrition?.protein_calories}
                        fat_calories={nutrition?.fat_calories}
                        carb_calories={nutrition?.carbs_calories}
                    />
                    <ExternalLinkCard />
                </div>
            </div>

            {description && (
                <Section title="Overview">
                    <p className="text-sm">{description}</p>
                </Section>
            )}

            {ingredientsList.length > 0 && (
                <Section title="Ingredients">
                    <ul className="list-disc pl-5 space-y-2">
                        {ingredientsList.map((ingredient, index) => (
                            <li key={index} className="text-sm">
                                {formatIngredient(ingredient)}
                            </li>
                        ))}
                    </ul>
                </Section>
            )}

            {parsed_instructions && parsed_instructions.length > 0 && (
                <Section title="Instructions">
                    <ul className="list-disc pl-5 space-y-2">
                        {parsed_instructions.map((instruction, index) => (
                            <li key={index} className="text-sm">{instruction}</li>
                        ))}
                    </ul>
                </Section>
            )}
        </div>
    );
}
