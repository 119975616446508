import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Button';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from 'src/components/DropdownMenu';
import { openAddExternalRecipeModal } from 'src/store/globalSlice';

function ActionLibrary() {
  const dispatch = useDispatch();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="reverse">
          Create
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Meal Plan</DropdownMenuLabel>
        <DropdownMenuItem asChild>
          <Link to="/build_meal_plan">Create Meal Plan</Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>Recipe Library</DropdownMenuLabel>
        <DropdownMenuItem onSelect={() => dispatch(openAddExternalRecipeModal())}>
          Add External Recipe
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link to="/create_recipe">Create Recipe</Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ActionLibrary;