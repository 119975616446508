import React, { useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import { supabase } from '../../lib/supabase-client';
import { CALLBACK_URL } from '../../constants';

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signInWithGoogle = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: CALLBACK_URL,
        },
      });
      if (error) throw error;
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (session) {
          const { user } = session;
          setCurrentUser({
            uid: user.id,
            displayName: user.user_metadata.full_name,
            email: user.email,
            photoURL: user.user_metadata.avatar_url,
          });
        } else {
          setCurrentUser(null);
        }
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  if (loading) {
    return <span className="loading loading-dots loading-lg" />;
  }

  return (
    <AuthContext.Provider value={{ currentUser, signInWithGoogle }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;