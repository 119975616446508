import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createClient } from '@supabase/supabase-js';
import { BASE_API_URL, SUPABASE_URL, SUPABASE_ANON_KEY } from '../constants';

// Initialize Supabase client
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: async (headers) => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const token = session.access_token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    tagTypes: ['RecipeLibrary', 'MealPlan', 'User'], // Add 'User' to tagTypes
    endpoints: (builder) => ({
      listRecipeLibrary: builder.query({
        query: () => {
          return {
            url: '/list_recipes',
            method: 'GET',
          }
        },
        transformResponse: (response) => {
          return response.recipes;
        },
        providesTags: ['RecipeLibrary'],
      }),
      deleteRecipe: builder.mutation({
        query: (recipeIds) => ({
          url: '/delete_recipes',
          method: 'DELETE',
          body: recipeIds,
        }),
        async onQueryStarted(recipeIds, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('listRecipeLibrary', undefined, (draft) => {
              recipeIds.saved_recipe_ids.forEach((id) => {
                const index = draft.findIndex((r) => r.id === id);
                if (index !== -1) {
                  draft.splice(index, 1);
                }
              });
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      removeRecipeFromQueue: builder.mutation({
        query: (recipeId) => ({
          url: `/remove_saved_recipe_from_queue/${recipeId}`,
          method: 'PUT',
        }),
        async onQueryStarted(recipeId, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('listRecipeLibrary', undefined, (draft) => {
              const recipe = draft.find((r) => r.id === recipeId);
              if (recipe) {
                recipe.queued = false;
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      addRecipeToQueue: builder.mutation({
        query: (recipeId) => ({
          url: `/add_saved_recipe_to_queue/${recipeId}`,
          method: 'PUT',
        }),
        async onQueryStarted(recipeId, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('listRecipeLibrary', undefined, (draft) => {
              const recipe = draft.find((r) => r.id === recipeId);
              if (recipe) {
                recipe.queued = true;
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      listMeals: builder.query({
        query: ({ startDate, endDate, mealPlanId, includeMealPlans = false, includeGroceryLists = false }) => {
          const params = {
            start_date: startDate,
            end_date: endDate,
            include_meal_plans: includeMealPlans,
            include_grocery_lists: includeGroceryLists,
          };
          if (mealPlanId) {
            params.meal_plan_id = mealPlanId;
          }
          return {
            url: '/list_meals',
            params: params,
          };
        },
        transformResponse: (response) => {
          response.meals.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            const mealOrder = ['breakfast', 'lunch', 'dinner', 'snack'];
            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;
            if (mealOrder.indexOf(a.meal_type) < mealOrder.indexOf(b.meal_type)) return -1;
            if (mealOrder.indexOf(a.meal_type) > mealOrder.indexOf(b.meal_type)) return 1;
            return 0;
          });
          return response;
        },
        providesTags: ['MealPlan'],
      }),
      createMeal: builder.mutation({
        query: (mealData) => ({
          url: '/create_meal',
          method: 'POST',
          body: mealData,
        }),
        invalidatesTags: ['MealPlan'],
      }),
      removeRecipeFromMeal: builder.mutation({
        query: ({ meal_id, recipe_id, startDate, endDate }) => ({
          url: '/remove_recipe_from_meal',
          method: 'POST',
          body: { meal_id, recipe_id },
        }),
        invalidatesTags: ['MealPlan', 'GroceryList'],
        async onQueryStarted({ meal_id, recipe_id, startDate, endDate }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('listMeals', { startDate, endDate }, (draft) => {
              const meal = draft.find(m => m.id === meal_id);
              if (meal) {
                meal.recipes = meal.recipes.filter(r => r.id !== recipe_id);
              }
            })
          );

          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      addRecipeToMeal: builder.mutation({
        query: ({ meal_id, recipe_id, multiplier }) => ({
          url: '/add_recipe_to_meal',
          method: 'POST',
          body: { meal_id, recipe_id, multiplier },
        }),
        invalidatesTags: ['MealPlan', 'GroceryList'],
      }),
      createGroceryList: builder.mutation({
        query: (groceryListData) => ({
          url: '/create_grocery_list',
          method: 'POST',
          body: groceryListData,
        }),
        invalidatesTags: ['MealPlan'],
      }),
      searchRecipesWithParams: builder.query({
        query: ({ searchTerm, maxValues = 15 }) => ({
          url: '/search_for_recipes',
          params: { search_term: searchTerm, max_values: maxValues }
        }),
        transformResponse: (response) => response.recipes,
      }),
      getMealPlan: builder.query({
        query: (mealPlanId) => ({
          url: `/get_meal_plan/${mealPlanId}`,
          method: 'GET'
        }),
        transformResponse: (response) => response.meal_plan,
        providesTags: (result, error, mealPlanId) => [{ type: 'MealPlan', id: mealPlanId }],
      }),
      publishMeals: builder.mutation({
        query: (mealIds) => ({
          url: '/publish_meals',
          method: 'POST',
          body: { meal_ids: mealIds },
        }),
        invalidatesTags: ['MealPlan', 'RecipeLibrary'],
      }),
      getGroceryList: builder.query({
        query: (groceryListId) => ({
          url: `/get_grocery_list/${groceryListId}`,
          method: 'GET',
        }),
        transformResponse: (response) => response.grocery_list,
        providesTags: ['GroceryList'],
      }),
      refreshGroceryList: builder.mutation({
        query: ({ groceryListId }) => ({
          url: `/refresh_grocery_list/${groceryListId}`,
          method: 'POST',
        }),
        invalidatesTags: ['GroceryList'],
      }),
      updateGroceryList: builder.mutation({
        query: ({ groceryListId, groceryList }) => ({
          url: `/save_grocery_list/${groceryListId}`,
          method: 'PUT',
          body: { grocery_list: groceryList },
        }),
        invalidatesTags: ['GroceryList'],
      }),
      getMeal: builder.query({
        query: (mealId) => ({
          url: `/get_meal/${mealId}`,
          method: 'GET',
        }),
        transformResponse: (response) => response.meal,
        providesTags: (result, error, mealId) => [{ type: 'Meal', id: mealId }],
      }),
      addExternalRecipe: builder.mutation({
        query: (recipeData) => ({
          url: '/add_saved_external_recipe',
          method: 'POST',
          body: recipeData,
        }),
        invalidatesTags: ['RecipeLibrary'],
      }),
      validateUrl: builder.mutation({
        query: (url) => ({
          url: '/validate_url',
          method: 'POST',
          body: { url },
        }),
      }),
      getUser: builder.query({
        query: () => ({
          url: '/get_user',
          method: 'GET',
        }),
        transformResponse: (response) => response,
        providesTags: ['User'],
      }),
      createUser: builder.mutation({
        query: () => ({
          url: '/create_user',
          method: 'POST',
        }),
        transformResponse: (response) => response,
        invalidatesTags: ['User'],
      }),
      updateUser: builder.mutation({
        query: (userData) => ({
          url: '/update_user',
          method: 'PUT',
          body: userData,
        }),
        transformResponse: (response) => response,
        invalidatesTags: ['User'],
      }),
      updateRecipeStrings: builder.mutation({
        query: ({ meal_plan_id, meal_idea_id, recipe_strings }) => ({
          url: '/update_recipe_strings',
          method: 'PUT',
          body: { meal_plan_id, meal_idea_id, recipe_strings },
        }),
        invalidatesTags: ['MealPlan'],
      }),
      updateMealIdea: builder.mutation({
        query: ({ meal_plan_id, meal_idea_id, new_meal_idea }) => ({
          url: '/update_meal_idea',
          method: 'PUT',
          body: { meal_plan_id, meal_idea_id, new_meal_idea },
        }),
        invalidatesTags: (result, error, { meal_plan_id }) => [
          { type: 'MealPlan', id: meal_plan_id }
        ],
      }),
      getHousehold: builder.query({
        query: () => ({
          url: '/get_household',
          method: 'GET',
        }),
        transformResponse: (response) => response.members,
        providesTags: ['Household'],
      }),
      createMealPlan: builder.mutation({
        query: (mealPlanData) => ({
          url: '/create_meal_plan',
          method: 'POST',
          body: mealPlanData,
        }),
        invalidatesTags: ['MealPlan'],
      }),
      clearMealPlanHistory: builder.mutation({
        query: (mealPlanId) => ({
          url: `/clear_meal_plan_history/${mealPlanId}`,
          method: 'POST',
        }),
        invalidatesTags: (result, error, mealPlanId) => [
          { type: 'MealPlan', id: mealPlanId },
          'MealPlan'
        ],
      }),
    })
});

export const {
  useListRecipeLibraryQuery,
  useDeleteRecipeMutation,
  useRemoveRecipeFromQueueMutation,
  useAddRecipeToQueueMutation,
  useListMealsQuery,
  useCreateMealMutation,
  useRemoveRecipeFromMealMutation,
  useAddRecipeToMealMutation,
  useCreateGroceryListMutation,
  useSearchRecipesWithParamsQuery,
  useGetMealPlanQuery,
  usePublishMealsMutation,
  useGetGroceryListQuery,
  useRefreshGroceryListMutation,
  useUpdateGroceryListMutation,
  useGetMealQuery,
  useAddExternalRecipeMutation,
  useValidateUrlMutation,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateRecipeStringsMutation,
  useUpdateMealIdeaMutation,
  useGetHouseholdQuery,
  useCreateMealPlanMutation,
  useClearMealPlanHistoryMutation,
} = apiSlice;
