import * as React from 'react'

import { cn } from 'src/lib/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

    React.useEffect(() => {
      const textarea = textareaRef.current
      if (textarea) {
        const adjustHeight = () => {
          textarea.style.height = 'auto'
          textarea.style.height = `${textarea.scrollHeight}px`
        }
        
        textarea.addEventListener('input', adjustHeight)
        return () => textarea.removeEventListener('input', adjustHeight)
      }
    }, [])

    return (
      <textarea
        className={cn(
          'flex w-full border-2 rounded-md text-white font-base selection:bg-primary selection:text-white border-white bg-gray-900 px-3 py-2 text-sm ring-offset-black placeholder:text-black/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 overflow-hidden',
          'placeholder:text-gray-300',
          className,
        )}
        ref={(element) => {
          textareaRef.current = element
          if (typeof ref === 'function') ref(element)
          else if (ref) ref.current = element
        }}
        {...props}
      />
    )
  },
)
TextArea.displayName = 'TextArea'

export { TextArea }