import React from 'react';
import { formatDate } from '../../utils';
import { Checkbox } from 'src/components/Checkbox';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/Select';

function BuildSchedule({ schedule, updateSchedule, household }) {
  const handleCheckboxChange = (mealId, userId, isChecked) => {
    updateSchedule(mealId, 'users', {
      ...schedule[mealId].users,
      [userId]: isChecked
    });
  };

  const toggleEveryone = (mealId, isChecked) => {
    const updatedUsers = {};
    household.forEach(member => {
      updatedUsers[member.user_id] = isChecked;
    });
    updateSchedule(mealId, 'users', updatedUsers);
  };

  const handleSelectorChange = (mealId, value) => {
    updateSchedule(mealId, 'leftoverFrom', value);
  };

  const getLeftoverOptions = (currentMeal) => {
    const options = [{ value: 'fresh', label: 'Fresh Meal' }];
    
    Object.values(schedule).forEach(meal => {
      if (new Date(meal.date) < new Date(currentMeal.date) || 
          (new Date(meal.date).getTime() === new Date(currentMeal.date).getTime() && 
           mealOrder.indexOf(meal.meal_type) < mealOrder.indexOf(currentMeal.meal_type))) {
        if (Object.values(meal.users).some(Boolean)) {
          options.push({
            value: meal.id,
            label: `Leftovers from ${formatDate(meal.date)}'s ${meal.meal_type}`
          });
        }
      }
    });

    return options;
  };

  const mealOrder = ['breakfast', 'lunch', 'snack', 'dinner'];

  const sortedSchedule = Object.values(schedule).sort((a, b) => {
    return new Date(a.date) - new Date(b.date) || 
           mealOrder.indexOf(a.meal_type) - mealOrder.indexOf(b.meal_type);
  });

  const groupedSchedule = sortedSchedule.reduce((acc, meal) => {
    const date = new Date(meal.date).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(meal);
    return acc;
  }, {});

  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-2 font-bold text-left">Date/Meal</th>
            <th className="p-2 font-bold text-center">Everyone</th>
            {household.map(member => (
              <th key={member.user_id} className="p-2 font-bold text-center">{member.name}</th>
            ))}
            <th className="p-2 font-bold text-left">Meal Type</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupedSchedule).map(([date, meals]) => (
            <React.Fragment key={date}>
              <tr>
                <td colSpan={household.length + 3} className="text-xl font-bold text-black text-left py-4">
                  {formatDate(new Date(date))}
                </td>
              </tr>
              {meals.map(meal => (
                <tr key={meal.id}>
                  <td className="text-left text-black p-2">
                    {meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)}
                  </td>
                  <td className="text-center p-2">
                    <Checkbox
                      className="border-black"
                      checked={Object.values(meal.users).every(Boolean)}
                      onCheckedChange={(checked) => toggleEveryone(meal.id, checked)}
                    />
                  </td>
                  {household.map(member => (
                    <td key={member.user_id} className="text-center p-2">
                      <Checkbox
                        className="border-black"
                        checked={meal.users[member.user_id]}
                        onCheckedChange={(checked) => handleCheckboxChange(meal.id, member.user_id, checked)}
                      />
                    </td>
                  ))}
                  <td className="text-left text-black p-2">
                    {Object.values(meal.users).some(Boolean) ? (
                      <Select
                      value={meal.leftoverFrom || 'fresh'}
                      onValueChange={(value) => handleSelectorChange(meal.id, value)}
                      >
                        <SelectTrigger className="w-[200px]" variant="neutral">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent variant="neutral">
                          {getLeftoverOptions(meal).map(option => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    ) : null}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BuildSchedule;
