import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editingMember: null,
  isHouseholdDialogOpen: false,
  householdDialogType: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEditingMember: (state, action) => {
      state.editingMember = action.payload;
    },
    clearEditingMember: (state) => {
      state.editingMember = null;
    },
    setHouseholdDialogOpen: (state, action) => {
      state.isHouseholdDialogOpen = action.payload;
    },
    setHouseholdDialogType: (state, action) => {
      state.householdDialogType = action.payload;
    },
  },
});

export const { 
  setEditingMember, 
  clearEditingMember,
  setHouseholdDialogOpen,
  setHouseholdDialogType,
} = profileSlice.actions;

export default profileSlice.reducer; 