import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchRecipesWithParamsQuery, useUpdateRecipeStringsMutation } from '../../store/apiSlice';
import { openAddToMealModal, openCreateRecipeModal } from './mealPlanSlice';
import RecipeItem from 'src/components/RecipeItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Button } from '../../components/Button';
import FocusedTextInput from '../../components/FocusedTextInput';
import CreateYourOwnRecipeButton from './CreateYourOwnRecipeButton';
import CreateRecipeModal from '../recipeGeneration/CreateRecipeModal';
import AddToMealModal from './AddToMealModal';

import 'swiper/css';
import 'swiper/css/navigation';

const RecipeSearch = ({ search_term, onCreateRecipe, mealIdeaId, mealPlanId, allRecipeStrings, index }) => {
    const dispatch = useDispatch();
    const recipeStringsStreaming = useSelector(state => state.mealPlan.recipeStringsStreaming);
    const swiperRef = useRef(null);
    const [currentSearchTerm, setCurrentSearchTerm] = useState(search_term);
    const [isCreateRecipeModalOpen, setIsCreateRecipeModalOpen] = useState(false);

    const [updateRecipeStrings] = useUpdateRecipeStringsMutation();

    // Update currentSearchTerm when search_term prop changes
    useEffect(() => {
        setCurrentSearchTerm(search_term);
    }, [search_term]);

    const { data: recipes, isLoading, isFetching, isError, refetch } = useSearchRecipesWithParamsQuery(
        { searchTerm: currentSearchTerm, maxValues: 15 },
        {
            skip: recipeStringsStreaming || !currentSearchTerm,
            refetchOnMountOrArgChange: true,
        }
    );

    useEffect(() => {
        if (!recipeStringsStreaming && currentSearchTerm) {
            refetch();
        }
    }, [recipeStringsStreaming, currentSearchTerm, refetch]);

    const handleAddToMeal = (recipeId, recipeName) => {
        dispatch(openAddToMealModal({ recipeId, recipeName }));
    };

    const handleSearchTermChange = async (newSearchTerm) => {
        setCurrentSearchTerm(newSearchTerm);
        const updatedRecipeStrings = [...allRecipeStrings];
        updatedRecipeStrings[index] = newSearchTerm;

        try {
            await updateRecipeStrings({
                meal_plan_id: mealPlanId,
                meal_idea_id: mealIdeaId,
                recipe_strings: updatedRecipeStrings
            }).unwrap();
            refetch();
        } catch (error) {
            console.error('Failed to update recipe strings:', error);
        }
    };

    const handleCreateRecipe = () => {
        setIsCreateRecipeModalOpen(true);
    };

    const handleCloseCreateRecipeModal = () => {
        setIsCreateRecipeModalOpen(false);
    };

    const handleSaveRecipe = (recipeId) => {
        setIsCreateRecipeModalOpen(false);
        dispatch(openAddToMealModal({ recipeId, recipeName: currentSearchTerm }));
    };

    return (
        <div className="mb-4">
            <div className="flex items-center justify-between mb-2">
                <FocusedTextInput
                    value={currentSearchTerm}
                    onFocusChange={handleSearchTermChange}
                    placeholder="Enter recipe search term"
                    className="w-full max-w-xs"
                />
                {recipes && recipes.length > 0 && (
                    <div>
                        <Button variant="neutral" className="mx-2" onClick={() => swiperRef.current?.slidePrev()}>
                            Back
                        </Button>
                        <Button variant="neutral" onClick={() => swiperRef.current?.slideNext()}>
                            Next
                        </Button>
                    </div>
                )}
            </div>
            {recipeStringsStreaming && <span className="loading loading-dots loading-lg"></span>}
            {!recipeStringsStreaming && isLoading && <span className="loading loading-dots loading-lg"></span>}
            {!recipeStringsStreaming && isError && <p className="text-destructive">Error fetching recipes</p>}
            {!recipeStringsStreaming && recipes && (
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={10}
                    slidesPerView="auto"
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    className="py-2"
                >
                    <SwiperSlide key="create-recipe" className="!w-auto">
                        <CreateYourOwnRecipeButton
                            searchTerm={currentSearchTerm}
                            onClick={handleCreateRecipe}
                        />
                    </SwiperSlide>
                    {recipes.map((recipe) => (
                        <SwiperSlide key={recipe.recipe_id} className="!w-auto">
                            <RecipeItem
                                recipe={recipe}
                                className="mb-2"
                                actions={[
                                    { actionText: 'Add to meal', actionHandler: () => handleAddToMeal(recipe.recipe_id, recipe.recipe_name) },
                                ]}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            <CreateRecipeModal
                isOpen={isCreateRecipeModalOpen}
                onClose={handleCloseCreateRecipeModal}
                initialMessage={currentSearchTerm}
                saveCallback={handleSaveRecipe}
            />
        </div>
    );
};

export default RecipeSearch;
