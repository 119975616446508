import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_WS_URL } from '../../constants';
import { useGetMealPlanQuery, useUpdateMealIdeaMutation } from '../../store/apiSlice';
import RecipeSearch from './RecipeSearch';
import { setRecipeStringsStreaming } from './mealPlanSlice';
import FocusedTextInput from '../../components/FocusedTextInput';

const MealIdea = ({ mealIdea }) => {
    const dispatch = useDispatch();
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const mealIdeasStreaming = useSelector(state => state.mealPlan.mealIdeasStreaming);
    const ws = useRef(null);
    const [recipeStrings, setRecipeStrings] = useState(mealIdea.recipe_strings);
    const recipeStringsStreaming = useSelector(state => state.mealPlan.recipeStringsStreaming);

    const { refetch } = useGetMealPlanQuery(meal_plan_id);
    const [updateMealIdea] = useUpdateMealIdeaMutation();

    useEffect(() => {
        if (!mealIdeasStreaming && mealIdea.recipe_strings.length === 0) {
            dispatch(setRecipeStringsStreaming(true));
            ws.current = new WebSocket(`${BASE_WS_URL}/generate_recipe_strings`);

            ws.current.onopen = () => {
                console.log('WebSocket is connected');
                console.log(mealIdea);
                ws.current.send(JSON.stringify({ payload: { meal_idea_id: mealIdea.meal_idea_id, meal_idea: mealIdea.meal_idea, meal_plan_id: meal_plan_id } }));
            };

            ws.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.recipe_names) {
                    console.log("Recipe names received");
                    setRecipeStrings(data.recipe_names);
                } else if (data.end_of_stream) {
                    console.log('End of stream received, closing WebSocket.');
                    ws.current.close();
                    ws.current = null;
                    refetch();
                    dispatch(setRecipeStringsStreaming(false));
                }
            };

            ws.current.onerror = (error) => {
                console.error('WebSocket error:', error);
                dispatch(setRecipeStringsStreaming(false));
            };

            ws.current.onclose = () => {
                console.log('WebSocket is closed');
                ws.current = null;
                dispatch(setRecipeStringsStreaming(false));
            };

            return () => {
                if (ws.current) {
                    ws.current.close();
                }
                dispatch(setRecipeStringsStreaming(false));
            };
        }
    }, [mealIdeasStreaming, mealIdea, dispatch, refetch, meal_plan_id]);

    const handleMealIdeaUpdate = async (newMealIdea) => {
        try {
            await updateMealIdea({
                meal_plan_id,
                meal_idea_id: mealIdea.meal_idea_id,
                new_meal_idea: newMealIdea
            }).unwrap();
            
            // After successful update, clear recipe strings
            setRecipeStrings([]);
            
            // Optionally, you might want to refetch the meal plan to get updated data
            refetch();
        } catch (error) {
            console.error('Failed to update meal idea:', error);
        }
    };

    return (
        <div className="flex flex-col h-full">
            <FocusedTextInput
                className="text-lg font-semibold mb-2"
                placeholder="Enter meal idea..."
                value={mealIdea.meal_idea}
                onFocusChange={handleMealIdeaUpdate}
            />
            {recipeStrings.length > 0 && (
                <div className="mt-2">
                    {recipeStrings.map((recipe, index) => (
                        <RecipeSearch
                            key={index}
                            search_term={recipe}
                            mealIdeaId={mealIdea.meal_idea_id}
                            mealPlanId={meal_plan_id}
                            allRecipeStrings={recipeStrings}
                            index={index}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MealIdea;