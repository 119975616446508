import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MealIdea from './MealIdea';
import RecipeLibraryTab from './RecipeLibraryTab';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../../components/Accordion';
import AddToMealModal from './AddToMealModal';

function MealIdeaTab() {
    const mealIdeas = useSelector((state) => state.mealPlan.mealIdeas);
    const sortedMealIdeas = Object.entries(mealIdeas).sort((a, b) => b[1].index - a[1].index);
    const mealIdeasStreaming = useSelector((state) => state.mealPlan.mealIdeasStreaming);
    const recipeStringsStreaming = useSelector((state) => state.mealPlan.recipeStringsStreaming);

    const [openItem, setOpenItem] = useState(null);
    useEffect(() => {
        if (mealIdeasStreaming) {
            setOpenItem(null);
        }
    }, [mealIdeasStreaming]);

    return (
        <div className="pt-4 pl-4">
            <h5 className="pb-2 font-bold">Meal Ideas</h5>
            <Accordion type="single" collapsible value={openItem} onValueChange={setOpenItem}>
                {sortedMealIdeas.map(([id, mealIdea]) => (
                    <AccordionItem key={id} value={id}>
                        <AccordionTrigger 
                            className="py-2 pr-4 hover:bg-gray-800" 
                            disabled={mealIdeasStreaming || recipeStringsStreaming}
                        >
                            <div className="text-left">
                                {mealIdea.meal_idea}
                            </div>
                        </AccordionTrigger>
                        <AccordionContent className="p-0">
                            <MealIdea mealIdea={mealIdea} />
                        </AccordionContent>
                    </AccordionItem>
                ))}
                <AccordionItem value="recipeLibrary">
                    <AccordionTrigger className="py-2 pr-4 hover:bg-gray-800">
                        <div className="text-left">
                            Recipe Library
                        </div>
                    </AccordionTrigger>
                    <AccordionContent className="p-0">
                        <RecipeLibraryTab />
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
            <AddToMealModal />
        </div>
    );
}

export default MealIdeaTab;
