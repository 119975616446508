import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '../../components/Button';
import HouseholdMemberDialog from './HouseholdMemberDialog';
import ProfileArea from './ProfileArea';
import NutritionBar from '../../components/NutritionBar';
import { setEditingMember, setHouseholdDialogOpen, setHouseholdDialogType } from './profileSlice';
import { useUpdateUserMutation } from '../../store/apiSlice';
const ManageHousehold = ({ user }) => {
  const dispatch = useDispatch();
  const editingMember = useSelector((state) => state.profile.editingMember);
  const [updateUser] = useUpdateUserMutation();
  const dialogType = useSelector((state) => state.profile.householdDialogType);
  const isHouseholdDialogOpen = useSelector((state) => state.profile.isHouseholdDialogOpen);

  const handleAddMember = (newMember) => {
    if (user && user.household) {
      const updatedHousehold = [...user.household, newMember];
      updateUser({ household: updatedHousehold });
    }
    dispatch(setHouseholdDialogOpen(false));
  };

  const handleUpdateMember = (updatedMember) => {
    if (user && user.household) {
      const updatedHousehold = user.household.map(member => 
        member.id === updatedMember.id ? updatedMember : member
      );
      updateUser({ household: updatedHousehold });
    }
    dispatch(setEditingMember(null));
    dispatch(setHouseholdDialogOpen(false));
  };

  const handleDeleteMember = (memberId) => {
    if (user && user.household) {
      const updatedHousehold = user.household.filter(member => member.id !== memberId);
      updateUser({ household: updatedHousehold });
    }
  };

  const handleOpenDialog = (member = null) => {
    dispatch(setHouseholdDialogType(member ? 'update' : 'add'));
    dispatch(setEditingMember(member));
    dispatch(setHouseholdDialogOpen(true));
  };

  const handleCloseDialog = () => {
    dispatch(setHouseholdDialogType(null));
    dispatch(setEditingMember(null));
    dispatch(setHouseholdDialogOpen(false));
  };

  return (
    <ProfileArea title="Your Household">
      <div className="flex flex-col gap-4">
        {user.household && user.household.map((member, index) => (
          <div key={member.id} className="border border-black bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center mb-4">
              <div className="text-lg font-semibold mr-4">
                {member.customer_facing_name || 'Not set'}
              </div>
              <div className="flex">
                <Button 
                  onClick={() => handleOpenDialog(member)} 
                  variant="reverse" 
                  size="sm" 
                  className="bg-gray-300 mr-2"
                >
                  Edit
                </Button>
                {index > 0 && (
                  <Button 
                    onClick={() => handleDeleteMember(member.id)} 
                    variant="reverse" 
                    className="bg-destructive" 
                    size="sm"
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>

            <div>
              <div className="text-sm font-semibold mb-2">Nutrition Targets</div>
              <NutritionBar
                size="lg"
                protein_calories={member.calorie_target * member.protein_percentage}
                fat_calories={member.calorie_target * member.fat_percentage}
                carb_calories={member.calorie_target * member.carb_percentage}
              />
            </div>
            <div>
              <div className="text-sm font-semibold mt-4 mb-2">Meal Schedule</div>
              <div className="grid grid-cols-4 gap-2">
                {Object.entries(member.calorie_allocation)
                  .sort(([mealA], [mealB]) => {
                    const mealOrder = {
                      breakfast: 1,
                      lunch: 2,
                      snack: 3,
                      dinner: 4
                    };
                    // Get order value or default to 5 (for other meals) + meal name for alphabetical sorting
                    const orderA = mealOrder[mealA] || 5 + mealA;
                    const orderB = mealOrder[mealB] || 5 + mealB;
                    return orderA - orderB;
                  })
                  .map(([meal, percentage]) => {
                    const percentageText = `${(percentage * 100).toFixed(0)}% (${Math.round(member.calorie_target * percentage)} cals)`;
                    return (
                      <div key={meal} className="text-sm font-['Space_Mono']">
                        <span className="capitalize">{meal}</span>: {percentageText}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ))}
      </div>

      <Button 
        onClick={() => {
          dispatch(setEditingMember(null));
          handleOpenDialog();
        }} 
        className="mt-4"
      >
        Add Household Member
      </Button>

      <HouseholdMemberDialog
        isOpen={isHouseholdDialogOpen}
        onClose={handleCloseDialog}
        onSave={dialogType === 'add' ? handleAddMember : handleUpdateMember}
        isNewMember={dialogType === 'add'}
      />
    </ProfileArea>
  );
};

export default ManageHousehold;
