import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../../components/Dialog';
import { Button } from '../../components/Button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/Select';
import { closeAddToMealModal } from './mealPlanSlice';
import { useAddRecipeToMealMutation, useListMealsQuery } from '../../store/apiSlice';
import { formatDate } from '../../utils';


const AddToMealModal = () => {
  const dispatch = useDispatch();
  const { isOpen, recipeId, recipeName } = useSelector(state => state.mealPlan.addToMealModal);
  const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
  const [selectedMealId, setSelectedMealId] = useState('');
  const [addRecipeToMeal] = useAddRecipeToMealMutation();
  const { data: mealsData } = useListMealsQuery({ mealPlanId: meal_plan_id });

  const handleClose = () => dispatch(closeAddToMealModal());

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedMealId && recipeId) {
      try {
        await addRecipeToMeal({
          meal_id: selectedMealId,
          recipe_id: recipeId,
          multiplier: 1,
        });
        handleClose();
      } catch (error) {
        console.error('Failed to add recipe to meal:', error);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
        const availableMeals = mealsData?.meals.filter(meal => meal.primary_meal === null);
        console.log(availableMeals);
        if (availableMeals.length === 1) {
            addRecipeToMeal({
                meal_id: availableMeals[0].id,
                recipe_id: recipeId,
                multiplier: 1,
            });
            handleClose();
        }
    }
}, [isOpen, mealsData]);


  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add {recipeName} to Meal</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="meal" className="block text-sm font-medium text-gray-300 mb-2">
              Select Meal
            </label>
            <Select value={selectedMealId} onValueChange={setSelectedMealId}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a meal" />
              </SelectTrigger>
              <SelectContent>
                {mealsData?.meals
                  .filter(meal => meal.primary_meal === null)
                  .map((meal) => (
                    <SelectItem key={meal.id} value={meal.id}>
                      {`${formatDate(meal.date)} - ${meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)}`}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <DialogFooter>
            <Button type="button" onClick={handleClose} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" disabled={!selectedMealId}>
              Add to Meal
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddToMealModal;