import React from 'react';
import { cn } from "src/lib/utils";

export default function Card({ children, className = '', isChecked, boxShadowColor = 'black', alwaysShowShadow = false, onClick = null }) {
    const handleClick = (e) => {
        if (e.defaultPrevented) return; // Don't call onClick if the event was already handled
        if (onClick) onClick(e);
    };

    return (
        <div 
            className={cn(
                "flex border border-black rounded transition-all",
                isChecked ? 'bg-gray-50' : 'bg-white',
                alwaysShowShadow ? 'shadow-offset' : 'hover:shadow-offset',
                "hover:-translate-x-boxShadowX hover:-translate-y-boxShadowY",
                className
            )}
            style={{ '--shadow-color': `var(--color-${boxShadowColor})` }}
            onClick={handleClick}
        >
            {children}
        </div>
    );
}
