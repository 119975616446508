import React from 'react';
import { Link } from 'react-router-dom';
import localIcon from '../assets/cooking_icon.png';
import Card from './Card';
import { formatDuration } from '../utils';
import { MultiActionButton } from './MultiActionButton';
import NutritionBar from './NutritionBar';
import { MoreHorizontal } from 'lucide-react';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from './DropdownMenu';
import { Button } from './Button';
import { useDispatch } from 'react-redux';
import { openRecipeSheet } from '../store/globalSlice';

export default function RecipeItem({ recipe, quantity = null, handleDelete = null, deleteText = 'Remove', className = '', actions = [], suppressProcessing = false, collapsedView = false}) {
    const dispatch = useDispatch();
    const faviconUrl = recipe.favicon;
    const displayName = quantity && quantity !== 1 ? `${quantity}x ${recipe.recipe_name}` : recipe.recipe_name;
    const imageUrl = recipe.image;
    const hasImage = imageUrl && imageUrl !== '';
    const domain = recipe.domain ? recipe.domain.replace(/^www\./, '') : '';
    const domainDescription = recipe.domain_description || domain;
    const nutrition = recipe.nutrition;
    console.log(nutrition);
    const hasNutrition = nutrition && (nutrition.protein_calories || nutrition.fat_calories || nutrition.carb_calories);
    const totalCalories = nutrition && (nutrition.calories || (nutrition.protein_calories + nutrition.fat_calories + nutrition.carb_calories));

    const recipeDetails = [
        recipe.rating_value && recipe.rating_count ? `Rating: ${Number(recipe.rating_value).toFixed(1)} (${recipe.rating_count})` : null,
        recipe.recipe_yield ? `Serves ${recipe.recipe_yield}` : null,
        formatDuration(recipe.total_time) ? formatDuration(recipe.total_time) : null,
        recipe.parsed_ingredients ? `${recipe.parsed_ingredients.length} ingredient${recipe.parsed_ingredients.length === 1 ? '' : 's'}` : null,
    ].filter(Boolean).join(' • ') || recipe.description;

    const finalActions = handleDelete
        ? [...actions, { label: deleteText, onClick: () => handleDelete(recipe.id) }]
        : actions.map(action => ({ label: action.actionText, onClick: () => action.actionHandler(recipe.id) }));

    const isNativeRecipe = recipe.recipe_type === 'native';
    const linkProps = isNativeRecipe
        ? { as: Link, to: `/recipe/${recipe.recipe_id}` }
        : { as: 'a', href: recipe.url, target: "_blank", rel: "noopener noreferrer" };

    const CollapsedCardContent = () => (
        <Card className={`max-w-sm ${className} group`} boxShadowColor='green'>
            <div className="flex items-center h-10 px-3 w-full">
                <img 
                        src={faviconUrl || localIcon} 
                    onError={(e) => { e.target.onerror = null; e.target.src = localIcon; }} 
                    className="w-4 h-4 flex-shrink-0 mr-3" 
                    alt="Recipe Icon" 
                />
                <div className="text-sm font-semibold line-clamp-1 text-black flex-grow">
                    {displayName}
                </div>
                <div 
                    className="ml-2" 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="noShadow" size="smIcon">
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {finalActions.map((action, index) => (
                                <DropdownMenuItem key={index} onClick={action.onClick}>
                                    {action.label}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </Card>
    );

    const ExpandedCardContent = () => (
        <Card className={`${className} group max-w-sm`} boxShadowColor='green'>
            <div className="flex flex-col w-full">
                <div className="flex h-[92px] items-center">
                    {hasImage && (
                        <div className="flex-shrink-0 mr-3">
                            <div className="w-[92px] h-[92px] overflow-hidden">
                                <img 
                                    src={imageUrl} 
                                    alt="Recipe"
                                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110 rounded-tl-base"
                                    style={{ objectPosition: 'center' }}
                                />
                            </div>
                        </div>
                    )}
                    <div className={`flex-grow min-w-0 pt-1 ${hasImage ? 'pr-2' : 'px-2'}`}>
                        <div className="text-md font-bold line-clamp-1 text-black">
                            {displayName}
                        </div>
                        <div className="flex items-center mt-1">
                            <img 
                                src={faviconUrl || localIcon} 
                                onError={(e) => { e.target.onerror = null; e.target.src = localIcon; }} 
                                className="w-3 h-3 flex-shrink-0" 
                                alt="Recipe Icon" 
                            />
                            <div className='pl-2 text-xs line-clamp-1 text-black'>{domainDescription}</div>
                        </div>
                        <div className="text-xs text-gray-800 mt-2 line-clamp-2">
                            {recipeDetails}
                        </div>
                    </div>
                </div>
                <div className="border-t border-black pt-2">
                    <div className="flex items-center px-2 pb-2">
                        <div className="mr-2" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            <MultiActionButton 
                                actions={finalActions}
                            />
                        </div>
                        {hasNutrition && (
                            <div className="flex-shrink-0 w-1/2">
                                <NutritionBar 
                                    protein_calories={nutrition.protein_calories} 
                                    fat_calories={nutrition.fat_calories} 
                                    carb_calories={nutrition.carbs_calories} 
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );

    const handleClick = (e) => {
        e.preventDefault();
        dispatch(openRecipeSheet(recipe));
    };

    return (
        <div onClick={handleClick} className="cursor-pointer">
            {collapsedView ? <CollapsedCardContent /> : <ExpandedCardContent />}
        </div>
    );
}
