import React from 'react';
import logo from '../assets/cooking_icon.png'

function Footer() {
  return (
    <footer className="footer items-center p-4 mt-8 bg-gray-100 border-t border-black text-neutral-content">
    <aside className="items-center grid-flow-col">
        <img src={logo} alt="Logo" width="48" height="48" className="fill-current" />
        <p className="text-black">Cooked up in Los Angeles</p>
    </aside> 
    </footer>
  );
}

export default Footer;