import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meal_plan_id: null,
  mealIdeas: {},
  mealIdeasStreaming: false,
  recipeStringsStreaming: false,
  adjustQuantityModal: {
    isOpen: false,
    recipeId: null,
    mealId: null,
    initialMultiplier: 1,
  },
  addToMealModal: {
    isOpen: false,
    recipeId: null,
    recipeName: null,
  },
};

const mealPlanSlice = createSlice({
  name: 'mealPlan',
  initialState,
  reducers: {
    setMealPlanId: (state, action) => {
      state.meal_plan_id = action.payload;
      state.mealIdeas = {};
    },
    setMealIdeas: (state, action) => {
      state.mealIdeas = action.payload;
    },
    setMealIdeasStreaming: (state, action) => {
      state.mealIdeasStreaming = action.payload;
    },
    setRecipeStringsStreaming: (state, action) => {
      state.recipeStringsStreaming = action.payload;
    },
    openAdjustQuantityModal: (state, action) => {
      state.adjustQuantityModal = {
        isOpen: true,
        recipeId: action.payload.recipeId,
        mealId: action.payload.mealId,
        initialMultiplier: action.payload.initialMultiplier,
      };
    },
    closeAdjustQuantityModal: (state) => {
      state.adjustQuantityModal = {
        isOpen: false,
        recipeId: null,
        mealId: null,
        initialMultiplier: 1,
      };
    },
    openAddToMealModal: (state, action) => {
      state.addToMealModal = {
        isOpen: true,
        recipeId: action.payload.recipeId,
        recipeName: action.payload.recipeName,
      };
    },
    closeAddToMealModal: (state) => {
      state.addToMealModal = {
        isOpen: false,
        recipeId: null,
        recipeName: null,
      };
    },
    adjustRecipeQuantity: (state, action) => {
      const { recipeId, multiplier } = action.payload;
      state.meals.forEach(meal => {
        const recipe = meal.recipes.find(r => r.recipe.recipe_id === recipeId);
        if (recipe) {
          recipe.multiplier = multiplier;
        }
      });
    },
    setRecipeIdeasStreaming: (state, action) => {
      state.recipeIdeasStreaming = action.payload;
    },
  }
});

export const {
  setMealPlanId,
  setMealIdeas,
  setMealIdeasStreaming,
  setRecipeStringsStreaming,
  openAdjustQuantityModal,
  closeAdjustQuantityModal,
  openAddToMealModal,
  closeAddToMealModal,
  adjustRecipeQuantity,
  setRecipeIdeasStreaming,
} = mealPlanSlice.actions;

export default mealPlanSlice.reducer;
