import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { formatDate } from '../../utils';

const ForTheWeek = ({ mealPlans, groceryLists }) => {
  const navigate = useNavigate();

  const analyzeGroceryList = (groceryList) => {
    let itemsForPurchase = 0;
    const recipeIds = new Set();

    Object.values(groceryList.grocery_list).forEach(category => {
      category.forEach(item => {
        if (item.checkbox_status === 'unchecked') {
          itemsForPurchase++;
          if (item.recipe_id) {
            recipeIds.add(item.recipe_id);
          }
        }
      });
    });

    return { itemsForPurchase, recipeCount: recipeIds.size };
  };

  const handleCardClick = (type, id) => {
    navigate(`/${type}/${id}`);
  };

  // Check if there are any meal plans or grocery lists to display
  if (mealPlans.length === 0 && groceryLists.length === 0) {
    return null; // Return null if both arrays are empty
  }

  return (
    <div className="mb-8">
      <h6 className="mb-4">For the Week</h6>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
        {mealPlans.map((plan, index) => {
          const mealsByDate = plan.meals.reduce((acc, meal) => {
            const date = meal.date;
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(meal.meal_type);
            return acc;
          }, {});

          const mealDescriptions = Object.entries(mealsByDate).map(([date, mealTypes]) => {
            return `<strong>${formatDate(date)}</strong> (${mealTypes.join(', ')})`;
          }).join(', ');

          return (
            <Card 
              key={`meal-plan-${index}`} 
              className="max-w-[384px] min-w-[256px] flex flex-col cursor-pointer transition-colors p-2"
              boxShadowColor="purple"
              onClick={() => handleCardClick('meal_plan', plan.id)}
            >
              <h5 className="font-bold">Meal Plan</h5>
              <p className="text-sm" dangerouslySetInnerHTML={{ __html: mealDescriptions }}></p>
            </Card>
          );
        })}
        {groceryLists.map((list, index) => {
          const { itemsForPurchase, recipeCount } = analyzeGroceryList(list);
          return (
            <Card 
              key={`grocery-list-${index}`} 
              className="max-w-[384px] min-w-[256px] flex flex-col cursor-pointer transition-colors p-2"
              boxShadowColor="blue"
              onClick={() => handleCardClick('grocery_list', list.id)}
            >
              <h5 className="font-bold">Grocery List</h5>
              <p className="text-sm">
                {itemsForPurchase} items for purchase
                {recipeCount > 0 && ` from ${recipeCount} recipes`}
              </p>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default ForTheWeek;