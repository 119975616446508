import React from 'react';
import { useNavigate } from 'react-router-dom';
import RecipeItem from '../../components/RecipeItem';
import { useListRecipeLibraryQuery, useDeleteRecipeMutation, useRemoveRecipeFromQueueMutation, useAddRecipeToQueueMutation } from 'src/store/apiSlice';
import Masonry from 'react-masonry-css';

export default function RecipeList({ additionalActions = [] }) {
    const navigate = useNavigate();

    const { data: recipes, error, isLoading } = useListRecipeLibraryQuery();
    const [deleteRecipe] = useDeleteRecipeMutation();
    const [removeRecipeFromQueue] = useRemoveRecipeFromQueueMutation();
    const [addRecipeToQueue] = useAddRecipeToQueueMutation();

    const handleDelete = async (recipeId) => {
        const recipeIds = { saved_recipe_ids: [recipeId] };
        await deleteRecipe(recipeIds);
    };

    const handleQueueToggle = async (recipeId, queued) => {
        if (queued) {
            await removeRecipeFromQueue(recipeId);
        } else {
            await addRecipeToQueue(recipeId);
        }
    };

    const breakpointColumnsObj = {
        default: 3,
        1024: 3,
        768: 2,
        522: 1
    };

    const renderRecipeSection = (recipes, title) => {
        if (recipes.length === 0) return null;

        return (
            <>
                {title && <h3 className="text-2xl font-bold mb-4">{title}</h3>}
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="flex -ml-4 w-auto"
                    columnClassName="pl-4 bg-clip-padding"
                >
                    {recipes.map(recipe => (
                        <div key={recipe.id} className="mb-4">
                            <RecipeItem
                                recipe={recipe}
                                className='w-full'
                                actions={[
                                    ...additionalActions.map(action => ({
                                        actionText: action.text,
                                        actionHandler: () => action.handler(recipe)
                                    })),
                                    { 
                                        actionText: recipe.queued ? 'Unqueue' : 'Queue', 
                                        actionHandler: () => handleQueueToggle(recipe.id, recipe.queued) 
                                    },
                                    { actionText: 'Delete from library', actionHandler: () => handleDelete(recipe.id) }
                                ]}
                            />
                        </div>
                    ))}
                </Masonry>
            </>
        );
    };

    return (
        <div className="w-full">
            <div className="masonry-wrapper">
                {isLoading && <span className="loading loading-dots loading-lg"/>}
        
                {recipes && (
                    <>
                        {recipes.some(recipe => recipe.queued) ? (
                            <>
                                {renderRecipeSection(recipes.filter(recipe => recipe.queued), 'Queued Recipes')}
                                {renderRecipeSection(recipes.filter(recipe => !recipe.queued), 'All Other Recipes')}
                            </>
                        ) : (
                            renderRecipeSection(recipes, null)
                        )}
                    </>
                )}
            </div>
        </div>
    );
}